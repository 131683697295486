import Table from "../../components/Mui/DataGrid";
import {useFormikContext, getIn} from "formik";
import {Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useEffect, useMemo, useState} from "react";
import AddEditStrawTable from "./AddEditStrawTable";
import {Stack} from "@mui/system";
import {run} from "../../utils/services";
import {colorDriverService} from "../../services/colorDriver.service";
import {hydraService} from "../../utils/hydra";
import {strawService} from "../../services/straw.service";
import Alert from "../../components/Alert";
import * as React from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {contractService} from "../../services/contract.service";
import {useParams} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import {ROLES} from "../../constants/roles";

// https://stackoverflow.com/questions/59595653/how-to-render-an-editable-table-with-formik-2-and-react-table-7
const HarvestStrawTable = ({name, remove, push, replace}) => {
    const {values, setValues: setContractValues} = useFormikContext();
    const {isGranted} = useUserContext();
    const {id} = useParams();
    const [editRow, setEditRow] = useState();
    const [colorDrivers, setColorDrivers] = useState([]);
    const formikSlice = useMemo(() => getIn(values, name) || [], [values, name]);
    // Create index

    useEffect(() => {
        let active = true;
        loadDriverColors();
        return () => {
            active = false
        }

        async function loadDriverColors() {
            const response = await run(colorDriverService.getAllColorsDrivers, {pagination: false, year: values.year});
            setColorDrivers(hydraService.getMembers(response.responseData));
        }
    }, [values.year]);

    const rows = useMemo(() => {
        const data = formikSlice
            .map((el, i) => ({...el, id: i}));
        const sum = data.reduce((acc, i) => {
            const tonnage = acc.tonnage + Number(i.tonnage);
            const balesNumber = acc.balesNumber + Number(i.balesNumber);
            return {
                tonnage: tonnage,
                balesNumber: balesNumber,
                averageBaleWeight: balesNumber > 0 ? tonnage / balesNumber : tonnage,
            }
        }, {
            tonnage: 0,
            balesNumber: 0,
            averageBaleWeight: 0,
        });

        return data.concat({
            id: formikSlice.length,
            color: 'Total',
            action: 'Total',
            tonnage: sum.tonnage.toFixed(2),
            balesNumber: sum.balesNumber.toFixed(2),
            averageBaleWeight: sum.averageBaleWeight.toFixed(2)
        });
    }, [formikSlice]);

    const [open, setOpen] = useState(false);
    const [row, setRow] = useState();

    const handleClickOpen = (row) => {
        setRow(row)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        run(strawService.removeDeclarationStrawHarvest, {id: hydraService.getIdFromIri(row)})
            .then(() => {
                reloadContract().then(()=>{
                    handleClose();
                });
            });
    }

    const reloadContract = () => {
        return run(contractService.getContract, id)
            .then((response) => {
                setContractValues(response.responseData);
            });
    }

    const columns = [
        {
            field: 'color',
            headerName: '',
            flex: 1,
            sortable: false,
        },
        {field: 'tonnage', headerName: 'Tonnage', flex: 1},
        {field: 'balesNumber', headerName: 'Nombre de balles', flex: 1},
        {field: 'averageBaleWeight', headerName: 'Poids moyen des balles', flex: 1},
        {
            field: 'action',
            type: 'actions',
            headerName: '',
            flex: 1,
            sortable: false,
            getActions: ({row, id}) => {
                if (row['action'] || isGranted(ROLES.MEMBER)) {
                    return [];
                }
                const index = rows.findIndex(el => el.id === id);
                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        onClick={() => handleRowClick({row, index})}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={() => handleClickOpen(row, index)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleRowClick = (params) => {
        setEditRow({...params.row});
    };

    return (
        <>
            <Alert handleClose={handleClose} open={open} handleRemove={handleRemove}/>
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography color="primary" fontWeight="bold">Recolte paille</Typography>
                <AddEditStrawTable title="Recolte paille" replace={replace} rows={rows} push={push} name={name}
                                   toUpdatedData={editRow} colorDrivers={colorDrivers}/>
            </Stack>
            <Table
                rows={rows}
                columns={columns}
                hideFooter
                // onRowClick={handleRowClick}
            />
        </>
    );
}
export default HarvestStrawTable;