import MuiTableAsync from "../../../../components/Mui/DataGridAsync";
import React, {useMemo, useState} from "react";
import {
    Button, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import {useDialog} from "../../../../feature/DialogProvider";
import _ from "lodash";
import {toLocaleDate} from "../../../../utils/i18n";
import {ticketService} from "../../../../services/ticket.service";
import DateFilter from "../../../../components/Filters/DateFilter";
import {Stack} from "@mui/system";
import CheckboxFilter from "../../../../components/Filters/CheckboxFilter";
import {run} from "../../../../utils/services";
import {engagementsService} from "../../../../services/engagements.service";
import snackBar from "../../../../components/SnackBar";
import {hydraService} from "../../../../utils/hydra";
import Confirmation from "../../../../feature/Confirmation";

const FabricationValidateDialog = () => {

    const [, closeDialog] = useDialog();

    const [filters, setFilters] = useState({});

    const [selection, setSelection] = useState([]);

    const columns = useMemo(() => (
        [
            {
                field: 'number', headerName: 'N° Ticket', flex: 1
            },
            {
                field: 'year', headerName: 'Année', flex: .5
            },
            {
                field: 'company', headerName: 'Adhérent', flex: 1.8
            },
            {
                field: 'deliveredWeight',
                headerName: 'Poids livré',
                flex: .7,
                valueFormatter: (params) => `${_.numberFormat(params.value)} T`,
                align: 'right'
            },
            {
                field: 'colorManufacturing', headerName: 'Couleur', flex: 1.5
            },
            {
                field: 'uniformity', headerName: 'Homogénéité', flex: 1
            },
            {
                field: 'pollution', headerName: 'Pollution', flex: 1
            },
            {
                field: 'presentation', headerName: 'Présentation', flex: 1
            },
            {
                field: 'fabricationDate',
                headerName: 'Date fabrication',
                flex: 1,
                valueFormatter: (params) => toLocaleDate(params.value)
            },
            {
                field: 'pebbleWeight',
                headerName: 'Poids cailloux',
                flex: 1,
                valueFormatter: (params) => `${_.numberFormat(params.value)} kg`,
                align: 'right'
            },
            {
                field: 'humidityRate',
                headerName: 'Humidité',
                flex: .7,
                valueFormatter: (params) => _.numberFormat(params.value),
                align: 'right'
            },
            {
                field: 'averageColorimetry',
                headerName: 'Colorimétrie',
                flex: .8,
                valueFormatter: (params) => _.numberFormat(params.value),
                align: 'right'
            },
        ]
    ), []);

    const handleSubmit = () => {
        const values = {
            check_all: false,
            date_start: null,
            date_end: null,
            ids: selection.map(iri => parseInt(hydraService.getIdFromIri(iri))),
            ...filters,
        };
        if (values.check_all === false && values.ids.length === 0) {
            return;
        }
        run(ticketService.updateFabricationValidate, values)
            .then((response) => {
                if (response.statusText === 'OK') {
                    snackBar(`${response.responseData.nb_success} tickets mis à jour.`);
                    closeDialog();
                }
            });
    }

    return (
        <>
            <DialogTitle className={'headerModal'}>
                Activation de l'onglet fabrication pour les tickets suivants
            </DialogTitle>
            <DialogContent className={'contentModal'}>
                <Stack direction={'row'}
                       spacing={2}
                       sx={{my: 3}}
                >
                    <DateFilter label={'Date début'} name={'date_start'} setFilters={setFilters}/>
                    <DateFilter label={'Date fin'} name={'date_end'} setFilters={setFilters}/>
                </Stack>
                <MuiTableAsync
                    columns={columns}
                    request={ticketService.getAllStrawTicketFabrication}
                    filters={filters}
                    customPagination
                    getRowId={(row) => row['id']}
                    checkboxSelection
                    keepNonExistentRowsSelected
                    isRowSelectable={({row}) => row.canShowFabrication}
                    onSelectionModelChange={setSelection}
                />
            </DialogContent>
            <DialogActions className={'footerModal'}>
                <Confirmation
                    buttonLabel={'Valider'}
                    disabled={selection.length === 0}
                    modalTitle={'Confirmer la sélection'}
                    modalContent={selection.length > 0 ? `Mettre à jour les ${selection.length} tickets sélectionnés ?` : 'Aucun ticket sélectionné.'}
                    handleConfirm={handleSubmit}
                    startIcon={''}
                    color={'primary'}
                />
                <Button onClick={closeDialog}>Annuler</Button>
            </DialogActions>
        </>
    );
}

export default FabricationValidateDialog;